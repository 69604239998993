import classNames from 'classnames'
import EmailValidator from 'email-validator'
import Img from 'gatsby-image'
import React, { useEffect, useRef, useState } from 'react'

import Button from 'components/button'
import WatchVideoButton from 'components/button/WatchVideo'
import PageHead from 'components/head/PageHead'
import Heading from 'components/heading/Heading'
import Checkbox from 'components/input/Checkbox'
import PageWrapper from 'components/layout/page-wrapper'
import DownloadSection from 'components/pages/shared/DownloadSection'
import CardanoData from 'data/pages/Contents/cardano.json'
import { MARCOM_SUBSCRIBE_CARDANO_PRE_SEED_PAGE } from 'js/analytics/trackers'
import { useRellax } from 'js/utils/hooks'
import buildImageObject from 'js/utils/buildImgObject'
import logEvent from 'js/analytics'
import { HELPERS_URL, X_URL } from 'src/constants'

import 'static/cardano/scss/styles.scss'

const page = 'cardano'
const data = CardanoData

const images = {
  headerImage: {
    aspectRatio: 1.35,
    alt: 'Exodus empowers people to control their wealth',
    path: '/cardano/img/',
    fileType: 'jpg',
    files: [
      {
        fileName: 'header-bg-ssize',
        width: 640,
      },
      {
        fileName: 'header-bg-msize',
        width: 1516,
      },
      {
        fileName: 'header-bg-lsize',
        width: 2400,
      },
    ],
  },
}

const inputValidation = (inputName, values, formState) => {
  if (formState === '') return null

  if (inputName === 'email') {
    if (!!values.email && EmailValidator.validate(values.email)) return 'success'
    if (!values.email || values.email === ' ') return 'success'
    if (!!values.email && !EmailValidator.validate(values.email)) return 'error'
    return null
  }

  if (inputName === 'name') {
    if (values.name) return 'success'
    if (!values.name || values.name === ' ') return 'error'
    return null
  }

  if (inputName === 'twitter') {
    if (values.twitter) return 'success'
    if (!values.twitter || values.twitter === ' ') return 'error'
    return null
  }

  return null
}

const BackgroundImage = ({ image, speed }) => {
  return (
    <div
      className={classNames('x__background-container', { 'cardano-rellax': speed })}
      data-rellax-speed={speed}
    >
      <div className="x__background">
        <Img fluid={buildImageObject(image)} alt={image.alt} durationFadeIn={2000} />
      </div>
    </div>
  )
}

const ChromeImage = ({ index, containerClassName, speed }) => {
  return (
    <span
      className={classNames(
        `${containerClassName}__chrome ${containerClassName}__chrome--${index}`,
        {
          'cardano-rellax': speed,
        }
      )}
      data-rellax-speed={speed}
      role="presentation"
    />
  )
}

const Input = ({ data, validation, onChange }) => {
  const inputRef = useRef(null)
  const [value, setValue] = useState('')

  const handleInputChanged = (e) => {
    setValue(e.target.value)
  }

  useEffect(() => {
    onChange(value)
  }, [value])

  return (
    <div className="x__form__input-wrapper">
      <input
        ref={inputRef}
        className={`x__form__input x__form__input--${data.name}`}
        name={data.name}
        type={data.type}
        placeholder={data.placeholder}
        onChange={handleInputChanged}
      />
      {validation && (
        <i className={`x__form__input-validation x__form__input-validation--${validation}`} />
      )}
    </div>
  )
}

const FormMessage = ({ copy }) => {
  return <p className="x__form__message">{copy}</p>
}

const TwitterButton = ({ name, copy, to }) => {
  return (
    <a
      href={to}
      target="_blank"
      className={`x__button-default x__button-default--largest x__button-default--${name}`}
    >
      <span className="x__copy">
        <svg
          viewBox="0 0 23 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid meet"
          xmlSpace="preserve"
        >
          <title>{`twitter-${name}`}</title>
          <path
            d="M.056 0l8.88 11.873L0 21.527h2.011l7.824-8.452 6.32 8.452H23L13.62 8.986 21.938 0h-2.011l-7.205 7.784L6.9 0H.056zm2.958 1.481h3.144l13.884 18.564h-3.144L3.014 1.481z"
            fill="#fff"
          />
        </svg>
        <span>{copy}</span>
      </span>
    </a>
  )
}

const HeaderSection = ({ data }) => {
  const [values, setValues] = useState({})
  const [disabled, setDisabled] = useState(true)
  const [formState, setFormState] = useState('')
  const [formMessage, setFormMessage] = useState('')

  const handleInputChange = (value, index) => {
    if (value === false) {
      setValues({ ...values, [index]: value })
      return null
    }
    setValues({ ...values, [index]: value })
  }

  const handleSubmitClick = (e) => {
    e.preventDefault()
    setDisabled(true)
    /* At least one value needs to be present */
    if (!values.twitter && !values.name) {
      setFormState('error')
      setFormMessage('Please enter a correct email address and twitter username')
      return
    }
    /* Validate 'email' field */
    if (!!values.email && !EmailValidator.validate(values.email)) {
      setFormState('error')
      setFormMessage('Please enter a correct email address')
      return
    }
    /* Show message if values are ok */
    setFormState('loading')
    setFormMessage('loading')
    /*
     * Submitting
     */
    fetch(`${HELPERS_URL}/users/twitter-campaign-subscription`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(values),
    })
      .then(async (response) => {
        const success = response.status === 200

        if (success) {
          /* Notice success */
          logEvent(MARCOM_SUBSCRIBE_CARDANO_PRE_SEED_PAGE)
          setFormState('success')
        } else {
          /* Notice if subscription fails */
          setFormState('error')
        }
      })
      .catch((err) => {
        /* Notice if subscription fails globally */
        console.log(err)
        setFormState('error')
        setFormMessage(err)
      })
  }

  useEffect(() => {
    const { email, name, twitter, terms } = values
    if ((!!email && !EmailValidator.validate(email)) || name === '' || twitter === '' || !terms)
      setDisabled(true)
    else setDisabled(false)
  }, [values])

  const formState0 = (
    <>
      {data.form.inputs &&
        data.form.inputs.map((input) => (
          <Input
            key={input.name}
            data={input}
            validation={inputValidation(input.name, values, formState)}
            onChange={(value) => handleInputChange(value, input.name)}
          />
        ))}

      {formMessage && <FormMessage copy={formMessage} />}

      {data.form.terms && (
        <Checkbox
          id="x__form__input--terms"
          variant="secondary"
          label={data.form.terms}
          checked={values.terms}
          onChange={(value) => handleInputChange(value, 'terms')}
        />
      )}

      <div className="x__form-actions">
        <Button
          copy={data.form.submit}
          disabled={disabled}
          size="largest"
          onClick={handleSubmitClick}
        />

        {data.form.links &&
          data.form.links.map((link) => (
            <a className="x__form__link" key={link.name} href={link.path}>
              {link.copy}
            </a>
          ))}
      </div>
    </>
  )

  const formState1 = data.form.message.success && (
    <div className="x__form-success">
      <h5 className="x__form-success x__form-success__heading">
        {data.form.message.success.headline}
      </h5>
      <p
        className="x__form-success x__form-success__description"
        dangerouslySetInnerHTML={{ __html: data.form.message.success.description }}
      />

      {data.form.message.success.links &&
        data.form.message.success.links.map((link) => (
          <TwitterButton
            key={link.name}
            name={link.name}
            copy={link.copy}
            to={link.name === 'follow' ? X_URL : '#'}
          />
        ))}
    </div>
  )

  return (
    <header className="x-header">
      <div className="x__content">
        <div className="x-header__container">
          <div className="x-header__container-headings cardano-rellax" data-rellax-speed="3">
            <Heading page={page} />

            {data.videoUrl && (
              <div className="x-header__buttons">
                <WatchVideoButton copy="Play video" href={data.videoUrl} />
              </div>
            )}
          </div>

          <div className="x-header__container-form">
            <div className="x__col">
              <Heading page={page} type="h2" section="header" />
              <ul className="x__list">
                {data.list &&
                  data.list.map((item, i) => (
                    <li
                      key={item.name}
                      className={`x__list x__list-item x__list-item--${item.name}`}
                    >
                      <span>{i + 1}. </span>
                      {item.copy}
                    </li>
                  ))}
              </ul>
            </div>

            <div className="x__col">
              <div className="x__form">{formState === 'success' ? formState1 : formState0}</div>
            </div>

            <ChromeImage index={1} containerClassName="x-header" speed={1.5} />
            <ChromeImage index={2} containerClassName="x-header" speed={1.0} />
            <ChromeImage index={3} containerClassName="x-header" speed={0.5} />
          </div>
        </div>
      </div>
    </header>
  )
}

function CardanoPreSeedPage({ location }) {
  useRellax({ className: 'cardano-rellax', center: false })

  /* Return content */
  return (
    <PageWrapper
      className="x-page-cardano"
      headerProps={{ isBannerHidden: true }}
      footerProps={{ enableSubscribeNotice: false, noBorder: true }}
    >
      <BackgroundImage image={images.headerImage} speed={-4} />
      <HeaderSection data={data.header} location={location} />
      <DownloadSection
        page={page}
        section="download"
        copy={data.download.copy}
        path={data.download.path}
      />
    </PageWrapper>
  )
}

export default CardanoPreSeedPage

// <head> component:
export function Head() {
  return <PageHead page={page} />
}
